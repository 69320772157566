import { useState } from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';

import { getLang } from 'libs/cookies';

import segments from 'shared/json/segments.json';
import originCountries from 'shared/json/originCountries.json';
import _categories from 'shared/json/categories.json';

const NewMetatagItem = ({ section, filter, metatagsItems, setMetatagsItems }) => {
  const { formatMessage } = useIntl();
  const [lang, setLang] = useState('es');

  const sectionsKeywords = {
    home: ['{site-title}'],
    girls: ['{site-title}', '{segment}'],
    trans: ['{site-title}', '{segment}'],
    couples: ['{site-title}', '{segment}'],
    room: ['{site-title}', '{nick}'],
    profile: ['{site-title}', '{nick}'],
    webcam: ['{site-title}', '{nick}'],
  };

  const sectionIds = {
    girls: 1,
    //boys: 3,
    trans: 2,
    couples: 4,
  };

  const buildPathname = () => {
    const buildedLang = lang === 'en' ? '' : lang;
    let url = `/${buildedLang}`;

    if (section === 'home') return url;
    else if (['room', 'webcam', 'profile'].includes(section)) url += `/${section}/[nick]`;
    else return buildSegmentPathname();

    // Remove doubles slashes
    return url.replace(/\/+/g, '/');
  };

  const buildSegmentPathname = () => {
    const buildedLang = lang === 'en' ? '' : lang;
    let url = `/${buildedLang}`;

    let segmentId = sectionIds[section];
    let segment = segments.find((segment) => segment.id === segmentId && segment.slugs[lang]);

    if (section in sectionIds) {
      if (segment) url += `/${segment.slugs[lang]}`;
    }

    if (filter) {
      let country = originCountries.find((country) => country.country === filter);
      if (country) url += `/${country.slugsFemale[lang]}`;

      let category = _categories.find((category) => category.segments.includes(segmentId) && category.id.toString() === filter.toString());
      if (category) url += `/${category.slugs[lang]}`;
    }

    return url.replace(/\/+/g, '/');
  };

  const buildTitle = () => {
    let title = '';

    switch (lang) {
      case 'es':
      default:
        title += formatMessage({ id: 'global-spanish' });
        break;
      case 'en':
        title += formatMessage({ id: 'global-english' });
        break;
      case 'de':
        title += formatMessage({ id: 'global-german' });
        break;
      case 'it':
        title += formatMessage({ id: 'global-italian' });
        break;
      case 'fr':
        title += formatMessage({ id: 'global-french' });
        break;
      case 'pt':
        title += formatMessage({ id: 'global-portuguese' });
        break;
    }

    switch (section) {
      case 'home':
      default:
        title += ' - Home';
        break;
      case 'girls':
        title += ' - ' + formatMessage({ id: 'global-girls' });
        break;
      case 'trans':
        title += ' - Trans';
        break;
      case 'couples':
        title += ' - ' + formatMessage({ id: 'global-couples' });
        break;
      case 'room':
        title += ' - ' + formatMessage({ id: 'global-room' });
        break;
      case 'webcam':
        title += ' - Webcam';
        break;
      case 'profile':
        title += ' - Perfil';
    }

    if (filter) {
      let segmentId = sectionIds[section];
      let country = originCountries.find((country) => country.country === filter);
      if (country) title += ` - ${country.demonymsFemale[getLang()]}`;

      let category = _categories.find((category) => category.segments.includes(segmentId) && category.id.toString() === filter.toString());
      if (category) title += ` - ${category.names[getLang()]}`;
    }

    return title;
  };

  const saveValue = (e) => {
    const { name, value } = e.target;
    const pathname = buildPathname();

    setMetatagsItems((prevState) => {
      const updatedState = { ...prevState };
      if (!updatedState[pathname]) {
        updatedState[pathname] = { title: '', description: '', h1: '', h2: '' };
      }
      updatedState[pathname][name] = value;
      return updatedState;
    });
  };

  const pathname = buildPathname();

  const saveLang = (e) => {
    e.preventDefault();
    const { lang } = e.target;
    setLang(lang);
  };

  return (
    <Card className="mb-3">
      <Card.Header>{buildTitle()}</Card.Header>
      <Card.Body>
        <Row className="text-center">
          <Col md="12">
            <FormattedMessage id="herramientas-white-label-metatag-keywords" />
          </Col>
        </Row>
        <Row className="text-center">
          <Col md="12">
            <div>
              {sectionsKeywords[section].map((keyword, index) => (
                <div key={index}>{keyword}</div>
              ))}
            </div>
          </Col>
        </Row>
        <br />
        <Row>
          <Col md="12">
            <div className="text-center d-flex w-100 border-bottom">
              <button onClick={saveLang} lang="es" className={`lang-button ${lang === 'es' ? 'active' : ''}`}>
                <FormattedMessage id="global-spanish" />
              </button>
              <button onClick={saveLang} lang="en" className={`lang-button ${lang === 'en' ? 'active' : ''}`}>
                <FormattedMessage id="global-english" />
              </button>
              <button onClick={saveLang} lang="de" className={`lang-button ${lang === 'de' ? 'active' : ''}`}>
                <FormattedMessage id="global-german" />
              </button>
              <button onClick={saveLang} lang="it" className={`lang-button ${lang === 'it' ? 'active' : ''}`}>
                <FormattedMessage id="global-italian" />
              </button>
              <button onClick={saveLang} lang="fr" className={`lang-button ${lang === 'fr' ? 'active' : ''}`}>
                <FormattedMessage id="global-french" />
              </button>
              <button onClick={saveLang} lang="pt" className={`lang-button ${lang === 'pt' ? 'active' : ''}`}>
                <FormattedMessage id="global-portuguese" />
              </button>
            </div>
          </Col>
        </Row>
        <br />
        <div>
          <Row>
            <Col md="12">
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="3" className="font-weight-bold">
                  Pathname
                </Form.Label>
                <Col sm="9">
                  <Form.Control value={pathname} placeholder="" readOnly />
                </Col>
              </Form.Group>

              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="3" className="font-weight-bold">
                  <FormattedMessage id="global-title" />
                </Form.Label>
                <Col sm="9">
                  <Form.Control
                    as="textarea"
                    name="title"
                    value={metatagsItems[pathname]?.title || ''}
                    onChange={saveValue}
                    placeholder=""
                    style={{ height: '60px' }}
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="3" className="font-weight-bold">
                  <FormattedMessage id="global-description" />
                </Form.Label>
                <Col sm="9">
                  <Form.Control
                    as="textarea"
                    name="description"
                    value={metatagsItems[pathname]?.description || ''}
                    onChange={saveValue}
                    placeholder=""
                    style={{ height: '60px' }}
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="3" className="font-weight-bold">
                  H1
                </Form.Label>
                <Col sm="9">
                  <Form.Control
                    as="textarea"
                    name="h1"
                    value={metatagsItems[pathname]?.h1 || ''}
                    onChange={saveValue}
                    placeholder=""
                    style={{ height: '60px' }}
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="3" className="font-weight-bold">
                  H2
                </Form.Label>
                <Col sm="9">
                  <Form.Control
                    as="textarea"
                    name="h2"
                    value={metatagsItems[pathname]?.h2 || ''}
                    onChange={saveValue}
                    placeholder=""
                    style={{ height: '60px' }}
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>
        </div>
      </Card.Body>
    </Card>
  );
};

export default NewMetatagItem;
